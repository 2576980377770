$background: #3b404b
$background-lighter: #4A515E
$white: #fafafa
$underline: #1583ec
$blue: $underline

$container-margin: auto 150px 150px 150px


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap')


*
  margin: 0
  padding: 0

html
  font-family: 'Open Sans', Arial, sans-serif
  font-size: 24px
  font-weight: 300

a, a:visited, a:hover, a:active
  color: $underline

body
  background: $background
  color: $white

header
  font-size: 3rem
  margin: 0 auto 4rem auto
  text-align: center

header span
  border-bottom: 3px solid $underline


.page
  max-width: 800px
  margin: 0 auto

  .section
    margin-bottom: 1.5rem

    .title
      display: block
      font-size: 1.5rem
      margin-bottom: 0.5rem

      a
        display: inline

    .sub
      margin-bottom: 1rem
    .sub:last-child
      margin-bottom: 0
