@import "base.sass"


*
  @media print
    color: #000
    background: #fff

a, a:visited, a:active, a:hover
  @media print
    color: #000
    text-decoration: none

html
  @media print
    font-size: 12px

body
  margin: 4rem

.resume
  .header
    text-align: right

    .name
      display: block
      font-size: 2rem
      font-weight: bold
    .sub
      display: block
      font-size: 1rem

  .section
    margin-bottom: 2rem
    text-align: left

    .name
      border-bottom: 1px solid $blue
      font-weight: bold
      font-size: 1.33rem
      max-width: 66%

      @media print
        border-bottom: 1pt solid #999

  .entry
    margin-bottom: 1.5rem

    ul
      margin-left: 2rem
      margin-top: 1rem

      ul
        margin-left: 1.5rem
        margin-top: 0

        @media print
          list-style-type: disc

    .tag-list
      margin-top: 0.5rem

      @media print
        display: none

      .tag
        font-size: 0.66rem
        padding: 0.25rem
        margin-right: 0.5rem
        border: 1px solid $blue
        border-radius: 8px

  hr
    border: 0
    border-bottom: 1px solid $background-lighter
    margin: 1rem 0
    max-width: 66%

    @media print
      display: none
      margin: 0
